import React from 'react'
import './header.scss'
import {Link} from 'react-router-dom';
import Logo from '../Assets/Images/Logo.svg';

const Header = () =>{
    return(
        <header className={'header'}>
            <img className={'logo'} src={Logo}/>
            <nav>
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/heroes">Heroes</Link>
                <Link to="/awards">Awards</Link>
                <Link className={'nominate'} to="/nominate">Nominate</Link>
                </nav>
        </header>
    )
}

export default Header
