import React from "react";
import '../Assets/CSS/ThankYou.scss'
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

const ThankYou = () => {
    ReactGA.send({ hitType: "pageview", page: "/thankyou" });
    return (
        <div className={"thankYouPage"}>
            <Helmet>
                <title>Thank you for your Nomination - Unsung Heroes from Ball State University</title>
                <meta name="description" content={"Check out the Unsung Heroes from Ball State University!"}/>
            </Helmet>
            <div className={'homeImgLineBox'}>
                <div className={'imgLine-3'}/>
                <div className={'imgLine-2'}/>
                <div className={'imgLine-1'}/>
            </div>
            <div className={"thankYouText"}>
                <div className={"thankYouH1"}>
                    <h1>Thank you for your nomination!</h1>
                </div>

                <h4>Our team will contact you if we have any follow-up questions about your nomination.
                    Be sure to check out our other<Link to={"/heroes"}> Unsung Heroes </Link> or explore
                    <Link to={"/awards"}> available awards!</Link></h4>
                <div className={"homeButton"}>
                    <Link to={"/"}>
                        <button> Go to home</button>
                    </Link>
                </div>
            </div>
            <div className={'thankYouBottomLineBox'}>
                <div className={'thankYouBottomLine-1'}/>
                <div className={'thankYouBottomLine-2'}/>
                <div className={'thankYouBottomLine-1'}/>
            </div>
        </div>
    )
}
export default ThankYou