import React from "react";
import '../Assets/CSS/Hero.css'

interface HeroCategory{
    id: number
    name: string
}

export type HeroProps ={
    nominee_name : string
    nominee_email ?: string
    description ?: string
    nominator_name ?: string
    nominator_email ?: string
    major ?: string
    year ?: string
    image ?: string
    article_url ?: string
    nomination_status ?: string
    heroCategory : HeroCategory
}

const Hero: React.FC<HeroProps> = (props) => {
    return(
        <div className={'individual-hero'}>
                    <img src={props.image}/>
                    <div className="hero-listing">
                        <div className={'hero-info'}>
                    <h1>
                        {props.nominee_name}
                    </h1>
                    <p>
                        {props.heroCategory.name}
                    </p>
                        </div>
                        <a href={props.article_url} className={'learn-more'} target={"_blank"} rel={"noopener noreferrer"}>Learn More</a>
                    </div>

        </div>
    )
}

export default Hero