import React, {useEffect, useState} from 'react'
import '../Assets/CSS/Home.scss'
import {Link} from "react-router-dom";
import Logo from "../Assets/Images/girl_1_no_background.svg"
import dotGraphic from '../Assets/Images/dotGraphic.svg'
import heroSectionImg from '../Assets/Images/Logo.svg'
import BottomLineGraphic from "../Components/BottomLineGraphic";
import Testimonial from "../Components/Testimonial";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";


const Home = () => {
    let mediaQuery = window.matchMedia("(max-width: 850px)").matches
    const [testimonials, setTestimonials] = useState<any[]>([]);
    const [activeIndex, setActiveIndex] = useState(0)
    let idNumCounter = -1

    useEffect(() => {
        const getTestimonials = async () => {
            const testimonialsFromServer = await fetch('https://heroes-api.apsoprojects.org/testimonials/')
            const testimonialData = await testimonialsFromServer.json()
            setTestimonials(testimonialData)
        }
        getTestimonials()
    }, [])
    useEffect(() => {
        initTestimonial()
    })

    const arrowClickHandler = (index: number) => {
        return (event: React.MouseEvent) => {
            for (let i = 0; i < testimonials.length; i++) {
                document.getElementById(`${i}`)!.style.backgroundColor = '#D2D2D2'
            }
            if (index < 0) {
                index = testimonials.length - 1
                setActiveIndex(index)
                document.getElementById(`${index}`)!.style.backgroundColor = '#929292'
            } else if (index > testimonials.length - 1) {
                index = 0
                setActiveIndex(index)
                document.getElementById(`${index}`)!.style.backgroundColor = '#929292'
            } else {
                setActiveIndex(index)
                document.getElementById(`${index}`)!.style.backgroundColor = '#929292'
            }
        }
    }

    const initTestimonial = () => {
        const testimonial = document.getElementById(`${activeIndex}`)
        if (testimonial == null) {
            return
        }
        testimonial.style.backgroundColor = "#929292"
    }

    const idCounter = () => {
        if (idNumCounter == -1) {
            idNumCounter = 0
            return 0
        } else {
            idNumCounter++
            return idNumCounter
        }
    }
    ReactGA.send({ hitType: "pageview", page: "/" });
    return (
        <div className={'homePage'}>
            <Helmet>
                <title>Unsung Heroes from Ball State University</title>
                <meta name="description" content={"Check out the Unsung Heroes from Ball State University!"}/>
            </Helmet>
            <div className={'homeTopSection'}>

                <div className={'textAndLines'}>

                    <div className={'homeTopLineGraphic'}>
                        <div className={'homeLineBox'}>
                            <div className={'homeLine-1'}/>
                            <div className={'homeLine-2'}/>
                            <div className={'homeLine-3'}/>
                        </div>
                    </div>

                    <div className={'homeTopSectionText'}>
                        <h1>Empower today.</h1>
                        <h1 className={'topRedH1'}>Inspire Tomorrow.</h1>
                        <p className={'topP'}>We believe students are capable of the incredible, even while they’re
                            still in school. Our mission is to diversify and amplify student recognition at Ball State
                            University.</p>
                        <h2 className={'topH2'}>Know someone who deserves recognition?</h2>
                        <Link to={'/Nominate'}>
                            <button className={'nominateButton'}>Nominate a student</button>
                        </Link>
                    </div>

                    <div className={'homeBottomLineBox'}>
                        <div className={'imgLine-3'}/>
                        <div className={'imgLine-2'}/>
                        <div className={'imgLine-1'}/>
                    </div>
                </div>
                <div className={'topSectionImage'}>
                    <img src={Logo} alt="Logo"/>
                    <div className={'homeImgLineBox'}>
                        <div className={'imgLine-3'}/>
                        <div className={'imgLine-2'}/>
                        <div className={'imgLine-1'}/>
                    </div>
                </div>
            </div>
            <div className={'homeBottomSection'}>
                <div className={'dotGraphic'}>
                    <img className={'dotGraphicImg'} src={dotGraphic} alt={'dotGraphic'}/>
                </div>
                <div className={'checkOutOurHeroesSection'}>
                    <div className={'checkOutSectionImage'}>
                        <img className={'heroSectionImg'} src={heroSectionImg} alt="Check out our heroes!"/>
                    </div>
                    <div className={'checkOutSectionText'}>
                        <p>Our students accomplish great things during their time at Ball State. Check out our recent
                            highlights to hear their stories!</p>
                        <Link to={'/heroes'}>
                            <button className={'checkOutButton'}>Check out our heroes</button>
                        </Link>
                    </div>
                </div>

                <h2>Here's what BSU is saying about Unsung Heroes...</h2>

                <div className={'testimonialSection'}>
                    <div className={"testimonialContainer"}>
                        <div className={'testimonials'}
                             style={
                            mediaQuery? {transform: `translateX(calc( ${activeIndex} * ( -80vw - 40px))`}
                                : {transform: `translateX(calc( ${activeIndex} * ( -50vw - 40px))`} }>
                            {testimonials && testimonials.map((testimonial) => {
                                return (
                                    <Testimonial
                                        width={40}
                                        id={idCounter()}
                                        quote={testimonial.quote}
                                        name={testimonial.name}
                                        title={testimonial.title}/>
                                )
                            })}
                        </div>
                    </div>

                    <div className={'arrowBox'}>
                        <div className={'leftArrow'} onClick={arrowClickHandler(activeIndex - 1)}>
                            <p className={'leftArrowButton'}>&lt;</p>
                        </div>
                        <div className={'rightArrow'} onClick={arrowClickHandler(activeIndex + 1)}>
                            <p className={'rightArrowButton'}>&gt;</p>
                        </div>
                    </div>
                </div>
                <div className={'bottomLineGraphicSection'}>
                    <BottomLineGraphic/>
                </div>
            </div>

        </div>
    )
}

export default Home
