import React from "react";
import '../Assets/CSS/TopSection.scss'


interface TopSectionProps {
    pageTitle?: string
    pageDescription?: string
}

const TopSection: React.FC<TopSectionProps> = (props) => {
    return (
        <div className='topSection'>
            <div className={'lineBox'}>
                <div className={'line-1'}/>
                <div className={'line-2'}/>
                <div className={'line-3'}/>
            </div>
                <div className={"homeTopSectionText"}>
                    <h1>{props.pageTitle}</h1>
                    <p>{props.pageDescription}</p>
                </div>
        </div>
    )
}

export default TopSection