import React, {SyntheticEvent, useEffect, useRef, useState} from 'react'
import '../Assets/CSS/Nominate.scss'
import TopSection from "../Components/TopSection";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";



const Nominate = () => {

    const [nominee_name,setNomineeName] = useState("")
    const [nominee_email,setNomineeEmail]= useState("")
    const [year,setYear]= useState("")
    const [major, setMajor]= useState("")
    const [description, setDescription]= useState("")
    const [heroCategory, setHeroCategory]= useState("1")
    const [img, setImg]= useState<File | string>('')
    const [nominator_name, setNominatorName]= useState("")
    const [nominator_email, setNominatorEmail]= useState("")
    const [preview, setPreview] = useState('')
    const [majors, setMajors] = useState([])

    ReactGA.send({ hitType: "pageview", page: "/nominate" });

    useEffect(() => {
        const getMajors = async () => {
            const majorsFromServer = await fetch("https://prod-digitalcorps.servers.bsu.edu/tools/bsu_degrees/")
            const data = await majorsFromServer.json()
            setMajors(data["bachelor-s-degrees"])
        }
        getMajors()
    }, [])

    const postHero = async (hero: { major: string; year: string; description: string; nominee_name: string; nominee_email: string; heroCategory: string; nominator_name: string; nominator_email: string }) => {
        const formData = new FormData()
        formData.append("nominee_name", hero.nominee_name)
        formData.append("nominee_email", hero.nominee_email)
        formData.append("description", hero.description)
        formData.append("nominator_email", hero.nominator_email)
        formData.append("nominator_name", hero.nominator_name)
        formData.append("major", hero.major)
        formData.append("year", hero.year)
        formData.append("file", img)
        formData.append("article_url", "")
        formData.append('nomination_status',"New")
        formData.append("hero_category_id", hero.heroCategory)
        console.log(img)
        await fetch('https://heroes-api.apsoprojects.org/heroes/', {
            method: 'POST',
            body: formData
        }).then((res) => {
            if (res.status >= 400 && res.status < 600) {
                alert("Bad response from server")
                console.log(res.json())
            }
            else {
                window.location.href="/thankyou"
                alert("Submission successful")
                resetForm()
                return res.json()
            }
        }).then( (data) =>{

        }).catch((error) => {
            alert("Unable to send to server and its your fault")
        })

    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        postHero({nominee_name ,nominee_email, year,
            major, description, heroCategory , nominator_name, nominator_email}).then((response) => {
            console.log(response)
        })

    }

    const resetForm = () =>{
        setNomineeName('')
        setNomineeEmail('')
        setYear('')
        setMajor('')
        setDescription('')
        setHeroCategory('1')
        setImg("")
        setNominatorName('')
        setNominatorEmail('')
    }
    const uploadBox = useRef<HTMLDivElement>(null)

    const activate = (e: SyntheticEvent) => {
        e.preventDefault()
        uploadBox.current?.classList.add("redBorder")
    }

    const deactivate = (e: SyntheticEvent) => {
        e.preventDefault()
        uploadBox.current?.classList.remove("redBorder")
    }

    const handleDrop = (e: any) =>{
        deactivate(e)
        const dataTransfer = e.dataTransfer
        const files = dataTransfer.files
        setImg(files[0])
        setPreview(URL.createObjectURL(files[0]))
        console.log(img)
    }

    return (
        <div>
            <div className={"topSection"}>
                <Helmet>
                    <title>Nominate a Hero- Unsung Heroes from Ball State University</title>
                    <meta name="description" content={"Honoring students for their efforts is important for their development into amazing heroes. It promotes the feelings of pride, creates a great sense of happiness, and can encourage the student to work even harder. Do you know a student or organization who is deserving of a Ball State Award? Checkout our awards below to nominate that deserving individual or organization."}/>
                </Helmet>
                <TopSection pageTitle={'Nominations'}
                            pageDescription={'Honoring students for their efforts is important for their development into amazing heroes. It\n' +
                                '                    promotes the feelings of pride, creates a great sense of happiness, and can encourage the student to\n' +
                                '                    work even harder. Do you know a student or organization who is deserving of a Ball State Award? Check\n' +
                                '                    out our awards below to nominate that deserving individual or organization.\n'}/>
            </div>
            <form className={"nominationForm"} onSubmit={e => onSubmit(e)}>
                <div className={"nomineeInfoSection"}>
                    <div>
                        <label htmlFor="nomineeName">Student nominee name* </label>
                        <input required={true} type="text" id={"nomineeName"} placeholder={"Charlie Cardinal"} value={nominee_name} onChange={ (e) => setNomineeName(e.target.value)}/>
                    </div>
                    <div>
                        <label htmlFor="nomineeEmail">Student nominee email* </label>
                        <input required={true} type="email" id={"nomineeEmail"} placeholder={"charlie@bsu.edu"} value={nominee_email} onChange={ (e) => setNomineeEmail(e.target.value)}/>
                    </div>
                    <div>
                        <label htmlFor="nomineeYear">Student nominee year </label>
                        <input type="text" id={"nomineeYear"} placeholder={"Freshman, Sophomore, etc."} value={year} onChange={ (e) => setYear(e.target.value)}/>
                    </div>
                    <div>
                        <label htmlFor="nomineeMajor">Student nominee major </label>
                        <input  list={'major'} id={"nomineeMajor"} placeholder={"Major"} value={major} onChange={ (e) => setMajor(e.target.value)} />
                        <datalist id={'major'}>
                            {majors && majors.map(({name}) => (
                                <option value={name}/>
                            ))}
                        </datalist>
                    </div>
                </div>
                <div className={"nominationDescriptionSection"}>
                    <label htmlFor="nominationDescription">Brief description of why you believe the nominee should be recognized (250 words or less)*</label>
                    <textarea required={true} id={"nominationDescription"} placeholder={`  Some questions to answer could include:
    - How do you know the nominee?
    - What is the nominee involved in on- or off-campus?
    - What makes this nominee worthy of recognition?
    - What are some characteristics you’ve noticed about the nominee that help them stand out?`} value={description} onChange={ (e) => setDescription(e.target.value)}/>
                 {/*Do not unindent, spacing is also displayed */}
                </div>
                <div className={"nominationCategorySection"}>
                    <h3> Please select a category for this nomination*</h3>
                    <p> Nomination categories help media outlets find students and effectively highlight their accomplishments. </p>
                    <div className={"dropDownMenu"}>
                        <select required={true} name="categoryDropDown" id="categoryDropDown" value={heroCategory} onChange={ (e) => setHeroCategory(e.target.value)}>
                            <option value="1">Academic Achievement</option>
                            <option value="5">Campus Involvement</option>
                            <option value="2">Community Impact</option>
                            <option value="4">Overcoming Hardships</option>
                            <option value="3">Professional Achievement</option>
                            <option value="6">Other</option>
                            {/*<option value="2">Group/Organizational</option>*/}
                            {/*<option value="3">Individual</option>*/}
                            {/*<option value="4">Philanthropy</option>*/}
                            {/*<option value="5">Scholarships</option>*/}
                            {/*<option value="6">Student Life</option>*/}
                        </select>
                    </div>
                </div>
                <div className={"uploadSection"}>
                    <h3>Would you like to upload an image of the nominee?</h3>
                    <div className={"uploadBox"} ref={uploadBox} onDragEnter={activate} onDragOver={activate} onDragLeave={deactivate} onDrop={handleDrop} >
                        <div className={"whiteRect"}></div>
                        <input type="file" accept=".heic/*,image/*" onChange={ (e) => {
                            if (e.target.files){
                                setImg(e.target.files[0])
                                setPreview(URL.createObjectURL(e.target.files[0]))
                            }
                        }}/>
                        <button type={"button"}> Upload Image</button>
                        <h3>or drop a file</h3>
                        {img && <img className={'image-preview'} src={preview}/>}
                    </div>
                </div>
                <div className={"submitterInfoSection"}>
                        <div>
                            <label htmlFor="nominatorName">Your name* </label>
                            <input required={true} type="text" id={"nominatorName"} placeholder={"Charlie Cardinal"} value={nominator_name} onChange={ (e) => setNominatorName(e.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="nominatorEmail">Your email* </label>
                            <input required={true} type="email" id={"nominatorEmail"} placeholder={"charlie@bsu.edu"} value={nominator_email} onChange={ (e) => setNominatorEmail(e.target.value)}/>
                        </div>
                </div>
                <button type={"submit"} >Submit Nomination</button>
            </form>
            <div className={'nominateBottomLineBox'}>
                <div className={'nominateBottomLine-1'}/>
                <div className={'nominateBottomLine-2'}/>
                <div className={'nominateBottomLine-1'}/>
            </div>
        </div>
    )
}

export default Nominate