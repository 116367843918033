import React from 'react';
import './App.scss';
import Home from './Pages/Home';
import About from './Pages/About';
import Heroes from './Pages/Heroes';
import Awards from './Pages/Awards';
import Nominate from './Pages/Nominate';
import Header from './Header/header'
import Footer from './Components/Footer'
import ReactGA from 'react-ga4'

import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import ThankYou from "./Pages/ThankYou";

function App() {

    ReactGA.initialize([
        {
            trackingId: "G-95Z7YYH1PJ",
        },
    ]);
  return (
    <div className="App">

        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />}/>
                <Route path="/awards" element={<Awards />} />
                <Route path="/heroes" element={<Heroes />} />
                <Route path="/nominate" element={<Nominate />} />
                <Route path={"/thankyou"} element={<ThankYou/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    </div>
  );
}

export default App;
