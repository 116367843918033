import React from 'react'
import TopSection from "../Components/TopSection";
import BottomLineGraphic from "../Components/BottomLineGraphic";
import '../Assets/CSS/About.scss'
import HatabImage from '../Assets/Images/HATABFADERA.svg'
import JalynImage from '../Assets/Images/JAYLYNGRAHAM.svg'
import MamieImage from '../Assets/Images/MAMIEKEITHGARARD.svg'
import HaileyImage from '../Assets/Images/HAILEYLEONARD.svg'
import DailyNewsImage from '../Assets/Images/DailyNews.svg'
import DigitalCorpsImage from '../Assets/Images/DigitalCorps.svg'
import McKinelyImage from '../Assets/Images/McKinleyAvenueAgency.svg'
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

const About = () =>{
    ReactGA.send({ hitType: "pageview", page: "/about" });
    return(
        <div className={"about"}>
            <Helmet>
                <title>About Us - Unsung Heroes from Ball State University</title>
                <meta name="description" content={"Check out the Unsung Heroes from Ball State University!"}/>
            </Helmet>
            <TopSection pageTitle={'About'} pageDescription={'The Unsung Heroes project started when our team noticed a lack of diversity in students highlighted by Ball State media. We asked ourselves the question: How might we acknowledge students who might not typically get public recognition for their accomplishments while being a student at BSU? With the help of our campus partners and some design thinking, Ball State Unsung Heroes was formed.'}/>
            <section>
            <h2>Team Members</h2>
            <ul className={'members'}>
                <li className={'member'}>
                <img src={HatabImage}/>
                    <h3>HATAB FADERA</h3>
                </li>
                <li className={'member'}>
                    <img src={JalynImage}/>
                    <h3>JAYLYN GRAHAM</h3>
                </li>
                <li className={'member'}>
                    <img src={MamieImage}/>
                    <h3>MAMIE KEITH GARARD</h3>
                </li>
                <li className={'member'}>
                    <img src={HaileyImage}/>
                    <h3>HAILEY LEONARD</h3>
                </li>
            </ul>
            <h2>Our Partners & Thank Yous</h2>
            <ul className={'partners'}>
                <li className={'partner'}>
                    <img src={DailyNewsImage}/>
                    <h3>Ball State Daily News</h3>
                </li>
                <li className={'partner'}>
                    <img src={DigitalCorpsImage}/>
                    <h3>Digital Corps</h3>
                </li>
                <li className={'partner'}>
                    <img src={McKinelyImage}/>
                    <h3>McKinley Avenue Agency</h3>
                </li>
            </ul>
            </section>
            <div className={'seeMoreDiv'}>
                <BottomLineGraphic/>
            </div>
        </div>
        
    )
}

export default About
