import React, {useEffect, useState} from 'react'
import '../Assets/CSS/Awards.css'
import Award from "../Components/Award";
import TopSection from "../Components/TopSection";
import BottomLineGraphic from "../Components/BottomLineGraphic";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

const Awards = () => {

    const [awards, setAwards] = useState([]);
    const [page, setPage] = useState(2);
    const [noMoreAwards, setNoMoreAwards] = useState(false)

    useEffect(() => {
        const getAwards = async () => {
            const awardsFromServer = await fetch('https://heroes-api.apsoprojects.org/awards/?page=1')
            const awardData = await awardsFromServer.json()
            setAwards(awardData.data)

            const awardsFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/awards/?page=${page+1}`)
            const dataNextPage = await awardsFromServerNextPage.json()
            if(dataNextPage.data.length == 0) {
                setNoMoreAwards(true)
            }
        }
        getAwards()
    }, [])

    const addMoreAwards = async () => {
        const awardsFromServer = await fetch(`https://heroes-api.apsoprojects.org/awards/?page=${page}`)
        const data = await awardsFromServer.json()
        setAwards(awards.concat(data.data));

        const awardsFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/awards/?page=${page+1}`)
        const dataNextPage = await awardsFromServerNextPage.json()
        if(dataNextPage.data.length == 0) {
            setNoMoreAwards(true)
        }
        setPage(page + 1)
    };
    ReactGA.send({ hitType: "pageview", page: "/awards" });
    return (
        <div className={'awardsPage'}>
            <Helmet>
                <title>Our Awards - Unsung Heroes from Ball State University</title>
                <meta name="description" content={"Honoring students for their efforts is important for their development into amazing heroes. It promotes the feelings of pride, creates a great sense of happiness, and can encourage the student to work even harder. Do you know a student or organization who is deserving of a Ball State Award? Check out our awards below to nominate that deserving individual or organization."}/>
            </Helmet>
            <TopSection pageTitle={'Awards'}
                        pageDescription={'Honoring students for their efforts is important for their development into amazing heroes. It promotes the feelings of pride, creates a great sense of happiness, and can encourage the student to work even harder. Do you know a student or organization who is deserving of a Ball State Award? Check out our awards below to nominate that deserving individual or organization.'}/>
            {awards.length !=0 && <div>
                <div className={'awardBoxes'}>
                {awards && awards.map(({image, award_name, awardCategory, is_featured, award_category_id, url}) => (
                    <Award image={image} award_name={award_name} is_featured={is_featured}
                           award_category_id={award_category_id} awardCategory={awardCategory} url={url}/>
                ))}
            </div>
                <div className={'seeMoreDiv'}>
                    {!noMoreAwards &&
                    <button className={'seeMoreButton'} onClick={addMoreAwards}>+ See more</button>
                    }
                <BottomLineGraphic/>
                </div>
            </div>}
            {awards.length == 0 && <div>
                <div className={'no-heroes-message'}>
                    <h3>No awards:</h3>
                    <p>There are currently no featured awards.</p>
                    <p>If you’d like to display an award, contact us at daily.news@bsu.edu.</p>
                </div>
            </div>}
        </div>
    )
}

export default Awards
