import React from "react";
import '../Assets/CSS/Award.css'

interface AwardCategory{
    id: number
    name: string
}

interface AwardProps {
    award_name : string
    description ?: string
    start_date?: Date
    end_date ?: Date
    image ?: string
    url ?: string
    is_featured : boolean
    award_category_id: number
    awardCategory: AwardCategory
}

const Award: React.FC<AwardProps> = (props) => {

    return(
        <div className={'individual-award'}>
            <img src={props.image} alt={'Award image'} className={'awardImage'}/>
            <div className="award-listing">
                <div className={'award-info'}>
                    <h1>
                        {props.award_name}
                    </h1>
                    <p>
                        { props.awardCategory && props.awardCategory.name}
                    </p>
                </div>
                    <a href={props.url} className={'learnMore'} target={"_blank"} rel={"noopener noreferrer"}>Learn More</a>
            </div>

        </div>
    )
}

export default Award