import React from "react";
import {Link} from "react-router-dom";
import '../Assets/CSS/Footer.scss';
import StyleBar from '../Assets/Images/StyleBar.svg'

const Footer = () => {
    return (
        <footer className={'footer'}>
            <nav className='nav-footer'>
                <Link className={'nominate-footer'} to="/nominate">Nominate</Link>
                <div className='nav-not-nominate'>
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/heroes">Heroes</Link>
                    <Link to="/awards">Awards</Link>
                    {/*<a target="_blank" href={"https://heroes-admin.apsoprojects.org/"}>Admin Login</a>*/}
                </div>

            </nav>
            <div className='red-bar-footer-div'>
                <img className='red-bar-footer' src={StyleBar} alt={'Red bars'}/>
            </div>
        </footer>

    )

}

export default Footer;