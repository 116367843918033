import React from "react";
import '../Assets/CSS/Testimonial.scss'

interface testimonialProps {
    width: number
    id: number
    quote: string
    created_at?: Date
    updated_at?: Date
    name?:string
    title?:string
}

const Testimonial: React.FC<testimonialProps> = (props) => {
    return(
        <div tabIndex={0} id={`${props.id}`} className={`testimonialBox`}>
            <div className={'quoteGraphic'}>
                <h1 className={'quoteH1'}>“</h1>
            </div>
            <div className={'quote'}>
                <h2>{props.quote}</h2>
            </div>
            <div className={'nameAndTitle'}>
                <h2>{props.name}</h2>
                <h2 className={'title'}>{props.title}</h2>
            </div>
        </div>
    )
}

export default Testimonial