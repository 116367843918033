import React from "react";
import '../Assets/CSS/BottomLineGraphic.scss'

const BottomLineGraphic = () => {
    return (
        <div className={'bottomLineBox'}>
            <div className={'bottomLine-3'}/>
            <div className={'bottomLine-2'}/>
            <div className={'bottomLine-1'}/>
        </div>
    )
}
export default BottomLineGraphic