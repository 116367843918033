import React, {useEffect, useState} from 'react'
import Hero, {HeroProps} from "../Components/Hero";
import '../Assets/CSS/Heroes.css'
import TopSection from "../Components/TopSection";
import RedBarsBottom from '../Assets/Images/RedBarsBottom.svg';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";

const Heroes = () => {
    ReactGA.send({ hitType: "pageview", page: "/heroes" });
    const [heroes, setHeroes] = useState([]);
    let [page, setPage] = useState(2);
    const [noMoreHeroes, setNoMoreHeroes] = useState(false)

    useEffect(() => {
        const getHeroes = async () => {
            const heroesFromServer = await fetch('https://heroes-api.apsoprojects.org/heroes/?page=1')
            const data = await heroesFromServer.json()

            const heroesFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/heroes/?page=${page+1}`)
            const dataNextPage = await heroesFromServerNextPage.json()
            if(dataNextPage.data.length == 0) {
                setNoMoreHeroes(true)
            }
            setHeroes(data.data.filter(((heroes: HeroProps) => heroes.nomination_status === 'Complete')))
        }
        getHeroes()
    }, [])

    const addMoreHeroes = async () => {
        const heroesFromServer = await fetch(`https://heroes-api.apsoprojects.org/heroes/?page=${page}`)
        const data = await heroesFromServer.json()
        setHeroes(heroes.concat(data.data));

        const heroesFromServerNextPage = await fetch(`https://heroes-api.apsoprojects.org/heroes/?page=${page+1}`)
        const dataNextPage = await heroesFromServerNextPage.json()
        if(dataNextPage.data.length == 0) {
            setNoMoreHeroes(true)
        }

        setPage(page+1)
    };

    return(
        <div>
            <Helmet>
                <title>Our Heroes - Unsung Heroes from Ball State University</title>
                <meta name="description" content={"Check out some recent acknowledgements of Unsung Heroes in the Ball State community! Click on a story below to see our heroes and their accomplishments."}/>
            </Helmet>
            <TopSection pageTitle={'Our Heroes'} pageDescription={'Check out some recent acknowledgements of Unsung Heroes in the Ball State community!\n' +
            '                Click on a story below to see our heroes and their accomplishments.'}/>

            {heroes.length != 0 && <div>
                <div className={"heroes-listing"}>
                    {heroes && heroes.filter((heroes: HeroProps) => heroes.nomination_status === 'Complete'
                    ).map(({image, heroCategory, nominee_name, article_url}) => (
                        <Hero image={image} nominee_name={nominee_name} heroCategory={heroCategory} article_url={article_url}/>
                    ))}
                </div>
                {!noMoreHeroes &&
                <button className='load-more-button' onClick={addMoreHeroes}>+ See More</button>
                }
                <div className='red-bar-bottom-div'>
                    <img className='red-bar-bottom' src={RedBarsBottom} alt={'Red bars'}/>
                </div>
            </div>}
            {heroes.length == 0 && <div>
                <div className={'no-heroes-message'}>
                    <h3>No heroes:</h3>
                    <p>Our heroes are still unsung!</p>
                    <p>If you know someone who deserves recognition, <a href={'/nominate'}>submit a nomination.</a></p>
                </div>
            </div>}

        </div>
    )
}

export default Heroes
